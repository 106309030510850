/** @format */

import React from "react";

const PrivacyPolicyComponent = () => {
  return (
    <>
      {/* <div className="faq-section mb-1">
                <div className="container">
                    <div className="row"> */}
      {/* <div className="col-md-12">
                <div className="feat-bg">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="faq-text">
                                <h3>NON-EMERGENCY MEDICAL TRANSPORTATION SOFTWARE</h3>
                            </div>
                        </div>
                        <div className="col-md-8 mgt-4 mb-2">
                        </div>
                        <div className="col-md-4">
                            <div className="did-find">
                                <div className="did-text">
                                    <h5>Didn't find an answer?</h5>
                                    <p>Send your question to our team, and we will add it to our FAQ page if we receive the same request from multiple people.</p>
                                </div>
                                <form action="#" className="mgt-4">
                                    <div className="mb-3">
                                        <input type="text" className="form-controlnew" id="exampleInputText1" aria-describedby="textHelp" placeholder="Full Name" />
                                    </div>
                                    <div className="mb-3">
                                        <input type="email" className="form-controlnew" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Your Email" />
                                    </div>
                                    <div className="mb-3">
                                        <textarea className="form-controlnew" rows="6" id="comment" name="text" placeholder="Your Question*"></textarea>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-secondarynew" style={{ padding: "5px 40px" }}>SEND</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="container">
        <div className="row contact-area justify-content-center">
          <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
            <div className="pricing-6-item mt-30">
              <div className="section-title-6 text-center">
                <h3 className="title">Privacy Policy</h3>
                <p></p>
              </div>
              <div className="contact-form">
                {/* <div><strong>Who we are</strong></div> */}
                <div>
                  At Connect Care Trans, we are committed to protecting your
                  privacy and ensuring the security of your personal
                  information. This Privacy Policy explains how we collect, use,
                  and safeguard the data you provide to us when using our ride
                  pooling Android or iOS app.
                </div>
                <br />
                <div>
                  <strong>1. Information We Collect:</strong>
                </div>
                <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                  <div>
                    - Email: We collect your email address to create and manage
                    your user account. It is used for communication purposes,
                    such as sending important updates and notifications.
                  </div>
                  <div>
                    - Name: We collect your name to personalize your user
                    experience within the app and to address you correctly.
                  </div>
                  <div>
                    - Profile Picture: We may give you the option to upload a
                    profile picture, which is used to personalize your user
                    account and enhance the social aspect of our app.
                  </div>
                  <div>
                    - Location: We collect your location to manage our fleet and
                    to optimize our routes.
                  </div>
                </div>
                <br />
                <div>
                  <strong>2. Use of Collected Information:</strong>
                </div>
                <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                  <div>
                    - Personalization: Your name and profile picture may be used
                    within the app to personalize your user experience and
                    interactions with other users.
                  </div>
                  <div>
                    - Communication: We may use your email address to send you
                    important announcements, updates, and notifications related
                    to the app's functionality, changes, or new features.
                  </div>
                  <div>
                    - Improvements: We may analyze the collected information to
                    improve our services, enhance user experience, and address
                    any technical issues that may arise.
                  </div>
                  <div>
                    - Management: We may use your location to find near-by users
                    for the ride and other services.
                  </div>
                  <div>
                    - Security: We take appropriate measures to protect the
                    security of your personal information and prevent
                    unauthorized access.
                  </div>
                </div>
                <br />
                <div>
                  <strong>3. Data Sharing:</strong>
                </div>
                <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                  <div>
                    - Third-Party Sharing: We do not share your personal
                    information, including your email, name, profile picture, or
                    location, with any third parties without your explicit
                    consent, unless required by law or for the purpose of
                    providing our services to you.
                  </div>
                  <div>
                    - SMS consent: SMS consent and phone numbers
                    collected\captured for SMS purposes will not be shared,
                    sold, or disclosed with any third parties, or any other
                    individual for any type of marketing purposes, or
                    affiliate marketing.
                  </div>
                  <div>
                    - Service Providers: We may engage trusted third-party
                    service providers who assist us in operating our app and
                    providing services to you. These service providers are
                    contractually obligated to maintain the confidentiality and
                    security of your personal information.
                  </div>
                </div>
                <br />
                <div>
                  <strong>4. Data Retention:</strong>
                </div>
                <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                  <div>
                    - We retain your personal information for as long as
                    necessary to fulfill the purposes outlined in this Privacy
                    Policy or as required by law. If you wish to delete your
                    account and associated data, please contact us at
                    support@connectcaretrans.net.
                  </div>
                </div>
                <br />
                <div>
                  <strong>5. Children's Privacy:</strong>
                </div>
                <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                  <div>
                    - Our app is not intended for use by children under the age
                    of 13. We do not knowingly collect personal information from
                    children. If you believe we may have inadvertently collected
                    personal information from a child, please contact us
                    immediately.
                  </div>
                </div>
                <br />
                <div>
                  <strong>6. Location Policy:</strong>
                </div>
                <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                  <div>
                    - Company has the option to track their drivers using the
                    geolocation system in Connect Care Trans.
                    {/* Company has the
                    option to activate or disable geolocation for their driver. */}
                  </div>
                  <div>
                    <strong>App Location Usage:</strong>
                    <br />
                    We may collect precise or approximate location from user's
                    mobile devices when the app is in use or in the background
                    if permission is granted by the user. This is used for
                    tracking the driver's location by Connect Care Trans when
                    he/she goes online. The user (driver) must grant permission
                    for foreground (App in use) and background location
                    tracking.
                    {/* when the schedule shift starts. */}
                  </div>
                </div>
                <br />
                <div>
                  <strong>7. Updates to the Privacy Policy:</strong>
                </div>
                <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                  <div>
                    - We reserve the right to update or modify this Privacy
                    Policy at any time. Any changes will be reflected on this
                    page, and we encourage you to review this policy
                    periodically. If you have any questions, concerns, or
                    requests regarding your personal information or this Privacy
                    Policy, please contact us at support@connectcaretrans.net.
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
        </div> */}
      {/* </div> */}
    </>
  );
};
export default PrivacyPolicyComponent;
