import React, { useEffect, useState } from 'react'
import NavBar from '../../components/landingWebsiteComponents/NavBar'
import Banner from '../../components/landingWebsiteComponents/Banner'
import Footer from '../../components/landingWebsiteComponents/Footer'
import { useDispatch, useSelector } from 'react-redux'
import AboutandServices from '../../components/landingWebsiteComponents/AboutandServices'
import BookingTab from '../../components/landingWebsiteComponents/BookingTab'
import { tabWhyUs } from '../../action/tabActions'
import { createEnternalCompany } from '../../action/contactusActions'

const Signup = () => {
    const dispatch = useDispatch()
    const auth = useSelector((x) => x.auth);

    useEffect(() => {
        dispatch(tabWhyUs())
    }, [])
    const [values, setValues] = useState({
        // companyId: "",
        companyId: auth?.user?.companyId?.id,
        
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",

        name: "",
        isCompany: false,
        
      });
      const handleChange = (fieldName) => (event) => {
        const value = event.target.value;
        
        if (fieldName === "isCompany") {
            // Check if the value is an empty string
            if (value === "") {
                setValues({
                    ...values,
                    [fieldName]: null, // or any default value you want
                });
            } else {
                setValues({
                    ...values,
                    [fieldName]: JSON.parse(value),
                });
            }
        } else {
            setValues({
                ...values,
                [fieldName]: value,
            });
        }
    };
    // const handleChange = (event) => {
    
    //     setValues({
    //       ...values,
    //       [event.target.name]: event.target.value,
    //     });
    //   };
    console.log(values, "values");
    const handlePhone = (fieldName) => (event) => {
        const numericValue = event.target.value.replace(/\D/g, "");
        let formattedValue = "";
        if (numericValue.length > 0) {
          formattedValue = `(${numericValue.slice(0, 3)}`;
        }
        if (numericValue.length > 3) {
          formattedValue += `)${numericValue.slice(3, 6)}`;
        }
        if (numericValue.length > 6) {
          formattedValue += `-${numericValue.slice(6, 10)}`;
        }
        setValues({
          ...values,
          [fieldName]: formattedValue,
        });
      };
    
  const companyData = {
    companyId: values.companyId,
    firstName: values.firstName ? values.firstName : values.name,
    lastName: values.lastName ? values.lastName : values.name,
    email: values.email,
    phone: values.phone,
    name: values.firstName,
    // isCompany: values.isCompany,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(createEnternalCompany(companyData));
  };

    return (
        <>
            <NavBar />
            <div class="conttext-section">
                <div class="container">
                <div class="row justify-content-center ">
                    <div class="col-lg-8 col-md-12 col-sm-12">
                        <div style={{backgroundColor: "#d8e2ec"}} class="p-3 p-sm-3 rounded">
                            <form class="row" action="#" onSubmit={handleSubmit}>
                                <div class="col-md-6 mb-3">
                                    <label for="fname" class="bokinglabel mb-2">First Name</label>
                                    <input class="form-control rounded-0 " type="text" name="fname" id="fname"
                                    value={values.firstName}
                                    // name="name"
                                    onChange={handleChange("firstName")}/>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="lname " class="bokinglabel mb-2">Last Name</label>
                                    <input class="form-control rounded-0 " type="text" name="lname" id="lname"
                                    value={values.lastName}
                                    // name="name"
                                    onChange={handleChange("lastName")}/>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="email" class="bokinglabel mb-2">Email</label>
                                    <input class="form-control rounded-0 " type="text" name="email" id="email"
                                    value={values.email}
                                    // name="name"
                                    onChange={handleChange("email")}/>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="phoneNumber" class="bokinglabel mb-2">Phone Number</label>
                                    <input class="form-control rounded-0 " type="tel" name="phoneNumber" id="phoneNumber"
                                    value={values.phone}
                                    onChange={handlePhone("phone")}
                                    placeholder="(123)456-7890"/>
                                </div>
                                
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="type" className="bokinglabel mb-2">Type</label>
                                    <select className="form-control rounded-0" name="type" id="type"
                                        onChange={handleChange("isCompany")}>
                                        <option value="">Select an option</option>
                                        <option value="false">Individual</option>
                                        <option value="true">Company</option>
                                    </select>
                                </div>
                                { values.isCompany === true && (
                                    <div class="col-md-6 mb-3">
                                        <label for="companyName" class="bokinglabel mb-2">Company Name</label>
                                        <input class="form-control rounded-0 " type="tel" name="name" id="companyName"
                                        value={values.name}
                                        // name="name"
                                        onChange={handleChange}/>
                                    </div>
                                )}

                                <div class="col-md-12 mb-3">
                                    <label for="password" class="bokinglabel mb-2">Password</label>
                                    <input class="form-control rounded-0 " type="password" name="password" id="password"
                                    value={values.password}
                                    // name="name"
                                    onChange={handleChange("password")}/>
                                </div>
                                <div class="col-md-12 text-center">
                                    <button class="btn btn-secondaryy btn-lg"
                                    >Sign Up</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Signup