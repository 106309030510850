/** @format */

import React from "react";
import HomeMainComponent from "../../src/components/landingWebsiteComponents/websiteHomeComponents/HomeMainComponent";
// import { tabHome } from '../../actions/tabActions'
import AboutandServices from "../../src/components/landingWebsiteComponents/AboutandServices";
import BookingTab from "../../src/components/landingWebsiteComponents/BookingTab";
// import logo1 from "../assets/images/logo-6.png";
import logo2 from "../assets/images/logo-5.png";
import logo3 from "../assets/images/logo-4.png";
// import logo1 from "../"
import NavBar from "../components/landingWebsiteComponents/NavBar";
import Footer from "../components/landingWebsiteComponents/Footer";

const Home = () => {
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(tabHome())
  // }, [])
  return (
    <>
      <NavBar />
      <HomeMainComponent />
      <AboutandServices />
      <div className="bgimg-section">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-md-12 mb-3">
              <div className="how-towork">
                <h4>PROCESS</h4>
                <h2>How Its Works</h2>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="iconbg mb-3">
                <i className="fa fa-phone"></i>
              </div>
              <div className="iconbook-text">
                <h3 style={{ color: "#000000" }}>1. Book</h3>
                <p>
                  Book your ride by contacting us directly or filling out our
                  booking form on the website.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="iconbg mb-3">
                <i className="fa fa-calendar"></i>
              </div>
              <div className="iconbook-text">
                <h3 style={{ color: "#000000" }}>2. Schedule </h3>
                <p>
                  We’ll schedule your desired ride for a smooth transportation
                  process.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="iconbg mb-3">
                <i className="fa fa-car"></i>
              </div>
              <div className="iconbook-text">
                <h3 style={{ color: "#000000" }}>3. Enjoy Our Service</h3>
                <p>
                  You’re ready to enjoy our hassle-free, non-emergency transport
                  service!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="conttext-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="we-offer">
                <h2>Key Service Differentiators</h2>
              </div>
            </div>
            {/* <div
              className="col-auto"
              // className="col text-start"
              style={{ textAlign: "right !important" }}
            >
              <a href="https://www.bbb.org/us/il/chicago/profile/medical-transportation/connect-care-trans-llc-0654-1000044520">
                <img src={logo1} alt="Cert1" className="logo-img2" />
              </a>
            </div> */}
            <div 
            className="col-auto"
            // className="col text-start"
            >
              <a href="#">
                <img src={logo3} alt="Cert2" className="logo-img2" />
              </a>
            </div>
            <div 
            className="col-auto"
            // className="col text-start"
            >
              <a href="#">
                {/* <img className="logo-img" src="assets/images/logo-2.jpg" alt=""/> */}
                <img src={logo2}  alt="Cert3" className="logo-img2" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <BookingTab />
      <Footer />
    </>
  );
};
export default Home;
