import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

// const searchOptions = {
//   componentRestrictions: { country: ["pk"] },
// };

export default function AutoComplete({
  handleSelect,
  coordinates,
  address,
  setAddress,
}) {
  return (
    <div>
      {/* <p>Enter Your Address</p> */}
      {/* <p className="text-primary text-md ">Enter Your Address</p> */}
      <PlacesAutocomplete
        // searchOptions={searchOptions}
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="mb-2 position-relative" style={{ width: "100%" }}>
            <input
              className="form-control height-35 f-14"
              style={{ width: "100%" }}
              {...getInputProps({ placeholder: "Search By Map" })}
              required
            />
            {/* <div className=""> */}
            {suggestions.length > 0 && (
              <div
                className="position-absolute bg-light py-4 px-2 w-100"
                style={{ zIndex: 50, maxHeight: 200, overflowY: "scroll" }}
              >
                {loading ? <div>...loading</div> : null}

                {suggestions.map((suggestion) => {
                  return (
                    <div
                      className={` border-grey rounded-md px-2 py-2
                        ${suggestion.active ? "bg-primary text-white" : ""}
                      `}
                      {...getSuggestionItemProps(suggestion)}
                    >
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          // </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
