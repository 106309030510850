/** @format */

import React, { useEffect } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import Banner from "../../components/landingWebsiteComponents/Banner";
import Footer from "../../components/landingWebsiteComponents/Footer";
import BookingTab from "../../components/landingWebsiteComponents/BookingTab";
import { useDispatch } from "react-redux";
import { tabServices } from "../../action/tabActions";
// import ser1 from "../../assets/images/ser-1.png";
// import ser2 from "../../assets/images/ser-2.png";
// import ser3 from "../../assets/images/ser-3.png";
// import underConstruction from "../../assets/images/undraw_under_construction_46pa.png";

const Services = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tabServices());
  }, []);
  return (
    <>
      <NavBar />
      <Banner name="Services" />
      <div className="conttext-section">
        <div className="container">
          <div className="row align-items-center justify-content-center mb-5">
            {/* <div className="col-lg-10 col-md-12 col-sm-12">
              <div className="serimg">
                <img
                  src={underConstruction}
                  style={{ height: "70vh", width: "auto" }}
                />
              </div>
            </div> */}
            <div className="d-flex justify-content-center align-items-center">
              <div className="serimg">
                <h2>Coming Soon</h2>
                {/* <img
                  src={underConstruction}
                  style={{ height: "500px", width: "auto" }}
                  alt="Under Construction"
                /> */}
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="serimg">
                <img src={ser1} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="ser-text">
                <h3 style={{ color: "#189A9A" }}>Quick and Reliable Service</h3>
                <p>
                  We know that your transportation needs don't stop. Using our
                  help line and our email service, you can arrange and schedule
                  non-emergency medical transportation with our highly
                  experienced team when it is convenient for you.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center mb-5">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="serimg">
                <img src={ser2} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="ser-text">
                <h3 style={{ color: "#189A9A" }}>Cheap Prices Available</h3>
                <p>
                  Punctuality and excellence are the cornerstones of our service
                  as a provider. You can rely on us to always be on time,
                  ensuring that our customers receive nothing but the finest
                  experience. Moreover, we are committed to making our services
                  as affordable as possible, ensuring that everyone can access
                  and benefit from them without any financial burden. Your
                  satisfaction is our priority, and we take pride in delivering
                  top-notch services at the most competitive rates.
                </p>
                <p>So, give us a call today at (773) 340-4373</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="serimg">
                <img src={ser3} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="ser-text">
                <h3 style={{ color: "#189A9A" }}>Multiple Patient Transport</h3>
                <p>
                  If you need to transport multiple patients, we can provide you
                  with transport of two wheelchairs and two ambulatory at a
                  time. Call us today for a reliable and stress-free service at
                  your door.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <BookingTab />
      <Footer />
    </>
  );
};
export default Services;
